import { CommonModule } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
	MAT_DIALOG_DATA,
	MatDialogActions,
	MatDialogClose,
	MatDialogContent,
	MatDialogRef,
	MatDialogTitle
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BehaviorSubject, interval, Observable, scan, Subscription, take, takeWhile, tap } from 'rxjs';
import { TimeLeftPipe } from '../../shared/pipes/time-left.pipe';
import { AuthService } from '../auth-service.service';

export interface SessionExpiringSoonDialogComponentData {
	sessionExpiringTimeInMinutes: number;
}

@Component({
	selector: 'session-expiring-soon-dialog',
	templateUrl: 'session-expiring-soon-dialog.component.html',
	standalone: true,
	imports: [
		MatFormFieldModule,
		MatInputModule,
		FormsModule,
		MatButtonModule,
		MatDialogTitle,
		MatDialogContent,
		MatDialogActions,
		MatDialogClose,
		CommonModule,
		TimeLeftPipe
	]
})
export class SessionExpiringSoonDialogComponent {
	@Input()
	public data: SessionExpiringSoonDialogComponentData | null = null;

	private _logoutTimerSubscription: Subscription | null = null;

	private _remainingTimeSeconds: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);

	public $remainingTimeSeconds: Observable<number | null> = this._remainingTimeSeconds.asObservable();

	constructor(
		@Inject(MAT_DIALOG_DATA)
		readonly _data: SessionExpiringSoonDialogComponentData,
		private readonly _dialogRef: MatDialogRef<SessionExpiringSoonDialogComponent>,
		private readonly _authService: AuthService
	) {
		if (_data) {
			this.data = _data;
		}

		this.startTimer(this.data?.sessionExpiringTimeInMinutes ?? 0);
	}

	public startTimer(minutes: number) {
		const countdown$ = interval(1000).pipe(
			scan(ticks => --ticks, minutes * 60),
			takeWhile(t => t >= 0),
			tap(value => {
				this._remainingTimeSeconds.next(value);
			})
		);

		// Subscribe to the observable.
		this._logoutTimerSubscription = countdown$.subscribe(remainingTimeSeconds => {
			if (remainingTimeSeconds <= 0) {
				this.stopTimer();
				this.logout();
			}
		});
	}

	public logout(): void {
		this._authService.logout().subscribe(response => {
			console.log('logout response', response);
			if (response.ok) {
				this.close();
			} else {
				console.error('failed to logout');
			}
		});
	}

	public refreshSession(): void {
		this._authService
			.refreshSession()
			.pipe(
				take(1),
				tap(response => {
					console.log('refreshSession response', response);
					if (response.ok) {
						this.close();
					} else {
						console.error('failed to refresh session');
					}
				})
			)
			.subscribe();
	}

	private stopTimer() {
		if (this._logoutTimerSubscription) {
			this._logoutTimerSubscription.unsubscribe();
			this._logoutTimerSubscription = null;
		}

		this._remainingTimeSeconds.next(null);
	}

	private close(): void {
		this.stopTimer();
		this._dialogRef.close();
	}
}
