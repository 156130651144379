import { Component } from '@angular/core';
import { ErrorPageComponent } from '../error-page';

@Component({
	standalone: true,
	selector: 'tenant-access-denied',
	templateUrl: './tenant-access-denied.component.html',
	styleUrl: './tenant-access-denied.component.scss',
	imports: [ErrorPageComponent]
})
export class TenantAccesDeniedComponent {}
