import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { provideRouter } from '@angular/router';
import { ROUTES } from './app/app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { authErrorInterceptor, authInterceptor } from './app/auth';
import { backendDownInterceptor } from './app/shared';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { importProvidersFrom } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { provideNativeDatetimeAdapter } from '@ng-matero/extensions/core';

bootstrapApplication(AppComponent, {
	providers: [
		provideAnimationsAsync(),
		provideRouter(ROUTES),
		provideHttpClient(
			withFetch(),
			withInterceptors([backendDownInterceptor, authInterceptor, authErrorInterceptor])
		),
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: { appearance: 'outline', subscriptSizing: 'dynamic' }
		},
		provideNativeDatetimeAdapter(),
		importProvidersFrom(MatNativeDateModule)
	]
}).catch(err => console.error(err));
