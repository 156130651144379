import { Routes } from '@angular/router';
import { AuthGuard } from './auth';
import { TenantNotProvidedComponent } from './error-pages';
import { BackendDownComponent } from './error-pages/backend-down';
import { PageNotFoundComponent } from './error-pages/page-not-found/page-not-found.component';
import { TenantAccesDeniedComponent } from './error-pages/tenant-access-denied';
import { AppTenantComponent } from './layout';
import { AppLayoutComponent } from './layout/app-layout/app-layout.component';
import { SandBoxComponent } from './sandbox/sandbox.component';
import { TenantGuard } from './shared';

export const ROUTES: Routes = [
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'tenant-not-provided'
	},
	{
		path: 'sandbox',
		component: SandBoxComponent,
		data: { breadcrumb: 'Sandbox', animation: 'sandbox' }
	},
	{ path: 'backend-down', pathMatch: 'full', component: BackendDownComponent },
	{
		path: 'page-not-found',
		pathMatch: 'full',
		component: PageNotFoundComponent,
		data: { animation: 'page-not-found' }
	},
	{
		path: 'tenant-access-denied',
		pathMatch: 'full',
		component: TenantAccesDeniedComponent,
		data: { animation: 'tenant-access-denied' }
	},
	{
		path: 'tenant-not-provided',
		pathMatch: 'full',
		component: TenantNotProvidedComponent,
		data: { animation: 'tenant-not-provided' }
	},
	{
		path: ':tenantCode',
		canActivate: [TenantGuard],
		component: AppTenantComponent,
		children: [
			{
				path: '',
				canActivate: [AuthGuard],
				component: AppLayoutComponent,
				loadChildren: () => import('./patient-list/patient-list.routes').then(m => m.PATIENTLISTROUTES)
			},
			{
				path: 'encounter/:id',
				canActivate: [AuthGuard],
				component: AppLayoutComponent,
				loadChildren: () =>
					import('./patient-dashboard/patient-dashboard.routes').then(m => m.PATIENTDASHBOARDROUTES)
			},
			{
				path: 'admin',
				canActivate: [AuthGuard],
				component: AppLayoutComponent,
				loadChildren: () => import('./admin/admin.routes').then(m => m.ADMINROUTES)
			},
			{
				path: 'user-settings',
				canActivate: [AuthGuard],
				component: AppLayoutComponent,
				loadChildren: () => import('./user-settings/user-settings.routes').then(m => m.USERSETTINGSROUTES)
			},
			{
				path: 'support',
				canActivate: [AuthGuard],
				component: AppLayoutComponent,
				loadChildren: () => import('./support/support.routes').then(m => m.SUPPORTROUTES)
			},
			{
				path: 'historical-records',
				canActivate: [AuthGuard],
				component: AppLayoutComponent,
				data: { breadcrumb: 'Historical Records', animation: 'historical-records' },
				loadChildren: () =>
					import('./historical-records/historical-records.routes').then(m => m.HISTORICALRECORDSROUTES)
			},
			{
				path: 'auth',
				loadChildren: () => import('./auth/auth.routes').then(m => m.AUTHROUTES)
			},
			{
				path: 'sandbox',
				component: SandBoxComponent,
				data: { breadcrumb: 'Sandbox', animation: 'sandbox' }
			},
			{ path: 'backend-down', component: BackendDownComponent },
			{
				path: 'page-not-found',
				component: PageNotFoundComponent,
				data: { animation: 'page-not-found' }
			},
			{ path: '**', component: PageNotFoundComponent, data: { animation: 'page-not-found' } }
		]
	}
];
