import { Component } from '@angular/core';
import { ErrorPageComponent } from '../error-page';

@Component({
	standalone: true,
	selector: 'backend-down',
	templateUrl: './backend-down.component.html',
	styleUrl: './backend-down.component.scss',
	imports: [ErrorPageComponent]
})
export class BackendDownComponent {}
