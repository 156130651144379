import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ChildrenOutletContexts, RouterModule } from '@angular/router';
import { BaseComponent } from '../../shared';
import { AppNavBarComponent } from '../app-nav-bar';
import { AppSidenavComponent } from '../app-sidenav';
import { fadeInOutRouteAnimation } from '../../shared/animations/fade-in-out-route-animation';

@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'layout',
	templateUrl: './app-layout.component.html',
	styleUrl: './app-layout.component.scss',
	imports: [RouterModule, AppNavBarComponent, AppSidenavComponent],
	animations: [fadeInOutRouteAnimation]
})
export class AppLayoutComponent extends BaseComponent {
	constructor(private readonly _contexts: ChildrenOutletContexts) {
		super();
	}

	public getRouteAnimationData() {
		return this._contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
	}
}
