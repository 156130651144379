import { Component } from '@angular/core';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { ChildrenOutletContexts, EventType, Router, RouterModule } from '@angular/router';
import { takeUntil, tap } from 'rxjs';
import { AuthService } from './auth';
import {
	SessionExpiringSoonDialogComponent,
	SessionExpiringSoonDialogComponentData
} from './auth/session-expiring-soon-dialog';
import { AppLoaderService, ConfirmationComponent, NotificationToastComponent } from './layout';
import { BaseComponent } from './shared';

@Component({
	standalone: true,
	selector: 'root',
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss',
	animations: [],
	imports: [NotificationToastComponent, ConfirmationComponent, RouterModule, MatDialogModule]
})
export class AppComponent extends BaseComponent {
	private _sessionExpiringSoonDialogRef: MatDialogRef<SessionExpiringSoonDialogComponent> | null = null;

	constructor(
		_matIconReg: MatIconRegistry,
		private _router: Router,
		private _appLoaderService: AppLoaderService,
		private _dialog: MatDialog,
		private _authService: AuthService,
		private _contexts: ChildrenOutletContexts
	) {
		super();
		_matIconReg.setDefaultFontSetClass('material-symbols-outlined');

		/** listen for route navigation changes. */
		this._router.events.pipe(takeUntil(this._destroy)).subscribe(e => {
			this.navigationInterceptor(e.type);
		});

		this._authService.$sessionTimeLeftMS
			.pipe(
				takeUntil(this._destroy),
				tap(value => {
					if (value > 0) {
						this._authService.restartLogoutWarningTimer(value);
					}
				})
			)
			.subscribe();

		this._authService.$sessionExpiringSoon
			.pipe(
				takeUntil(this._destroy),
				tap((sessionExpiringTimeInMinutes: number) => {
					this.openSessionExpiringSoonDialog(sessionExpiringTimeInMinutes);
				})
			)
			.subscribe();
	}

	getRouteAnimationData() {
		return this._contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
	}

	/** Shows and hides the loading spinner during RouterEvent changes. */
	private navigationInterceptor(eventType: EventType): void {
		switch (eventType) {
			case EventType.NavigationStart:
				this._appLoaderService.startLoadProcess();
				break;
			case EventType.NavigationEnd:
			case EventType.NavigationCancel:
			case EventType.NavigationError:
				this._appLoaderService.clearAllLoadProcessess();
				break;
			default:
				break;
		}
	}

	/** Show a dialog if the session is going to expire soon. */
	private openSessionExpiringSoonDialog(sessionExpiringTimeInMinutes: number): void {
		if (!this._sessionExpiringSoonDialogRef) {
			this._sessionExpiringSoonDialogRef = this._dialog.open(SessionExpiringSoonDialogComponent, {
				data: {
					sessionExpiringTimeInMinutes: sessionExpiringTimeInMinutes
				} as SessionExpiringSoonDialogComponentData,
				disableClose: true
			});
		}
	}
}
